export default {
  state: {
    userId: "", // ID
    username: "", // 名字
    userPic: "", // 图片
    UserPhone: "",
    LoginFlag: "",
  },
  getters: {
    userId: (state) => state.userId,
    username: (state) => state.username,
    userPic: (state) => state.userPic,
    UserPhone: (state) => state.UserPhone,
    LoginFlag: (state) => state.LoginFlag,
  },
  mutations: {
    setUserId: (state, userId) => {
      state.userId = userId;
    },
    setUsername: (state, username) => {
      state.username = username;
    },
    setUserPic: (state, userPic) => {
      state.userPic = userPic;
    },
    setUserPhone: (state, UserPhone) => {
      state.UserPhone = UserPhone;
    },
    setLoginFlag: (state, LoginFlag) => {
      state.LoginFlag = LoginFlag;
    },
  },
};
