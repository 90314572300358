import { createApp } from "vue";
import ElementPlus from "element-plus";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import jquery from "jquery";
import "element-plus/dist/index.css";
// import SlideVerify from 'vue-monoplasty-slide-verify';
// import "./assets/css/index.scss";
import "./assets/icons/index.js";

// import "./assets/js/jquery.min.js";
// import "./assets/js/bootstrap.min.js";
// import "./assets/js/jquery-plugin-collection.js";
// import "./assets/js/bootstrap-datepicker.min.js";
// import "./assets/js/script.js";

import "./assets/css/bootstrap.min.css";
// import "./assets/css/bootstrap-datepicker.min.css";
import "./assets/css/jquery.fancybox.css";
import "./assets/css/magnific-popup.css";
// import "./assets/css/themify-icons.css";
import "./assets/css/animate.css";
// import "./assets/css/owl.carousel.css";
// import "./assets/css/owl.theme.css";
// import "./assets/css/owl.transitions.css";
// import "./assets/css/slick.css";
// import "./assets/css/slick-theme.css";
import "./assets/css/style.css";
import "./assets/css/rate-common.css";
// import "./assets/css/rate-header.css";
import "./assets/css/rate-index.css";

import { ComponentCustomProperties } from "vue";
import { Store } from "vuex";
declare module "@vue/runtime-core" {
  interface State {
    count: number;
  }

  interface ComponentCustomProperties {
    $store: Store<State>;
  }
}
// .use(SlideVerify)
createApp(App).use(store).use(router).use(ElementPlus).mount("#app");
