import { Icon } from "@/enums";

export default {
  state: {
    /** 搜索参数 */
    SeaPOL: "",
    SeaPOD: "",
    SeaShipper: "",
    SeaisTrant:"",
  },
  getters: {
    SeaPOL: (state) => state.SeaPOL,
    SeaPOD: (state) => state.SeaPOD,
    SeaShipper: (state) => state.SeaShipper,
    SeaisTrant: (state) => state.SeaisTrant,
  },
  mutations: {
    setSeaPOL: (state, SeaPOL) => {
      state.SeaPOL = SeaPOL;
    },
    setSeaPOD: (state, SeaPOD) => {
      state.SeaPOD = SeaPOD;
    },
    setSeaShipper: (state, SeaShipper) => {
      state.SeaShipper = SeaShipper;
    },
    setSeaisTrant: (state, SeaisTrant) => {
      state.SeaisTrant = SeaisTrant;
    },
  },
  actions: {
    RateSearch: ({ commit }, { SeaPOD,SeaPOL,SeaShipper,SeaisTrant }) => {
      commit("setSeaPOL", SeaPOL);
      commit("setSeaPOD", SeaPOD);
      commit("setSeaShipper", SeaShipper);
      commit("setSeaisTrant", SeaisTrant);
    },
  },
};
