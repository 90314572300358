import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
  {
    path: "/404",
    redirect: "/",
    // component: () => import("@/views/error/404.vue"),
  },
  {
    path: "/",
    name: "RateContainer",
    component: () => import("@/views/RateContainer.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/signin",
        name: "signin",
        component: () => import("@/views/SignIn.vue"),
      },
      {
        path: "/signup",
        name: "signup",
        component: () => import("@/views/SignUp.vue"),
      },
      {
        path: "/personal",
        name: "personal",
        meta: {
          requireAuth: true,
        },
        component: () => import("@/views/personal/Personal.vue"),
      },
      {
        path: "/song-sheet",
        name: "song-sheet",
        component: () => import("@/views/song-sheet/SongSheet.vue"),
      },
      {
        path: "/song-sheet-detail/:id",
        name: "song-sheet-detail",
        component: () => import("@/views/song-sheet/SongSheetDetail.vue"),
      },
      {
        path: "/singer",
        name: "singer",
        component: () => import("@/views/singer/Singer.vue"),
      },
      {
        path: "/singer-detail/:id",
        name: "singer-detail",
        component: () => import("@/views/singer/SingerDetail.vue"),
      },
      {
        path: "/lyric/:id",
        name: "lyric",
        component: () => import("@/views/Lyric.vue"),
      },
      {
        path: "/search",
        name: "search",
        component: () => import("@/views/search/Search.vue"),
      },
      {
        path: "/personal-data",
        name: "personal-data",
        component: () => import("@/views/setting/PersonalData.vue"),
      },
      {
        path: "/setting",
        name: "setting",
        meta: {
          requireAuth: true,
        },
        component: () => import("@/views/setting/Upload.vue"),
        children: [
          {
            path: "/setting/PersonalData",
            name: "personalData",
            meta: {
              requireAuth: true,
            },
            component: () => import("@/views/setting/PersonalData.vue"),
          }
        ]
      },
      {
        path: "/RateSea",
        name: "RateSea",
        component: () => import("@/views/RateSea/RateSea.vue"),
      },
      {
        path: "/RateSeaOrder",
        name: "RateSeaOrder",
        component: () => import("@/views/RateSea/RateSeaOrder.vue"),
      },
      {
        path: "/RateAir",
        name: "RateAir",
        component: () => import("@/views/RateAir/RateAir.vue"),
      },
      {
        path: "/RateAirOrder",
        name: "RateAirOrder",
        component: () => import("@/views/RateAir/RateAirOrder.vue"),
      },
      {
        path: "/ShipDate",
        name: "ShipDate",
        component: () => import("@/views/ShipDate.vue"),
      },
      {
        path: "/ShipTrack",
        name: "ShipTrack",
        component: () => import("@/views/ShipTrack.vue"),
      },
      {
        path: "/UserCenter",
        name: "UserCenter",
        component: () => import("@/views/UserCenter.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
